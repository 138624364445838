import React from "react";
import { Switch, Route } from "react-router-dom";
import { AuthStartForm } from "./AuthStart";
import {AuthLogoutForm} from "./Logout";
import AuthForm from "./AuthForm";


export const AuthRouting = props => {
    return (
        <Switch>
            <Route exact path="/auth/logout" component={AuthLogoutForm} />
            <Route exact path="/auth/start" component={AuthStartForm} />
            <Route path="/auth" component={AuthForm} />
        </Switch>
    );
};

export default AuthRouting;
