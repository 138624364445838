import React from "react";

class InputField extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
            value: this.props.value
      }
      this.handleChange = this.handleChange.bind(this);
    }
    handleChange(e){
        this.setState({
          value: e.target.value
        });
        console.log({"input val":e.target.value});
        //this.props._onKeyDown(e.target.value);
    }
    render(){
      return (
        <input
          className    = 'form-control'
          type         = {this.props.type}
          id           = {this.props.id}
          name         = {this.props.name}
          defaultValue = {this.props.value}
          placeholder  = {this.props.placeholder}
          onKeyDown    = {this.handleChange}
        />
      );
    }
  } // InputField

  export default InputField;