import React from "react";
import { Redirect } from "react-router-dom";
import '../App.css';
//import { API } from "./API/api";
import { Func } from "./common/func";

export class AuthStartForm extends React.Component {
    constructor(props) {
        super(props);
		document.title = "Начальная страница";
        this.state = { redirect: "" };
    }
    componentDidMount() {
        var cfz = Func.get_sess_id();
		if(cfz == '' || cfz == null || cfz == undefined){
			//alert("уходим на форму авторизации");
			this.setState({ redirect: <Redirect to="/auth" /> });
		}else{
			//alert("Всё нормально: cfz = "+cfz);
			this.setState({ redirect: <Redirect to="/bases" /> });
		}
		/*
            .then(x => {
				alert("Всё нормально: х = "+x);
                //this.setState({ redirect: <Redirect to="/bases" /> });
            })
            .catch(x => {
				alert("уходим на форму авторизации");
                //this.setState({ redirect: <Redirect to="/auth" /> });
            });
		*/
    }
    render() {
        return (
			<div>
				<h2>Подождите ...</h2>
				<div>
				<i>... идёт загрузка системы ...</i>
				</div>
				{this.state.redirect}
			</div>
        );
    }
}
