import moment from "moment";
import "moment/locale/ru";
import { Qs } from "./Qs";

/**
 * Определенная ошибка. Определена через прототипы, потому что у меня
 * есть сомнения, что Babel правильно интерпретирует расширение ошибки
 * @param {string} message Сообщение об ошибке
 */
const DefinedError = function (message, inner) {
    this.name = "DefinedError";
    this.message = message;
    this.stack = new Error().stack;
    this.innerMessage = inner || "";
};
DefinedError.prototype = new Error();

export const API = (function () {
    var ns = {
        config: { url: "https://www.u1145825.isp.regruhosting.ru" },
        configLoaded: false,
        /**
         * В зависимости от того, запущен ли проект на локальной машине разработчика
         * возвращаем или хочт API из конфигурации или адрес заппущенной рядом cors-proxy.
         * Нужно для корректного проксирования cookie
         * @returns {string} Адрес API
         */
        getApiHost: () => {
            return 'https://www.u1145825.isp.regruhosting.ru';
        },

        /**
         * Делает запрос на заданный адрес с заданными данными.
         * В случае успеха ожидает корректный JSON, если такой есть, проверяет значение поля «status».
         * Если статус не в [200,299], вызывает исключение с текстом из поля «message».
         * Если JSON не корректен или запрос не завершен должным образом, вызывает исключение.
         * @param {Object} options Параметры запроса
         * @param {string} options.url Адрес метода API
         * @param {string} options.method Метод (GET|POST)
         * @param {(Object|FormData)} options.data Данные, для передачи в метод API
         * @returns {Promise}
         */
        call: async options => {
            var data = {
                credentials: "include",
                method: options.method
            };
            if (["GET", "HEAD"].indexOf(options.method) === -1)
                data.body = options.data;
            
            //alert("call: "+options.url);

            try {
                const x = await fetch(options.url, data);
                if (!x.ok)
                    throw new DefinedError("Ошибка соединения с сервером");

                const json = await x.json();

                if ((json.status || "200").toString().indexOf("2") !== 0)
                    throw new DefinedError(
                        json.message || json.result || "Неизвестная ошибка"
                    );

                return json;
            } catch (err) {
                if (DefinedError.prototype.isPrototypeOf(err)) {
                    throw err;
                }
                throw new DefinedError(
                    "Произошла ошибка соединения или распознавания ответа от сервера.",
                    err.message
                );
            }
        },
        /**
         * Вызывает метод API с заданными параметрами.
         * Если вызов происходит с локального адреса (только 127.0.0.1),
         * использует в качестве адреса API (127.0.0.1:3001) - middleware.
         * В других случаях адрес API из конфига (или 78.110.62.174:8007)
         * @param {string} point Имя метода API. Не должно начинаться с «/»
         * @param {(object|FormData)} data Данные для передачи в метод API
         * @param {string} [method=POST] Метод вызова API
         * @return {Promise}
         */
        point: function (point, data, method) {
            let apiHost = ns.getApiHost();
            let qs = "";
            //Если метод GET, формируем строку параметров
            if (method === "GET") {
                qs = Qs.form(data);
            }
            //alert(`http://${apiHost}/${point}${qs}` + apiHost);
            return ns.call({
                url: `${apiHost}/${point}/${qs}`,
                method: method || "POST",
                data: FormData.prototype.isPrototypeOf(data)
                    ? data
                    : JSON.stringify(data)
            });
        },
        /**
         * Авторизует пользователя, либо возвращает ошибку о том, что пользователь не авторизован.
         * В случае успеха задает авторизационную cookie, которая потом проверяется при каждом запросе
         * @param {Object} data Данные о пользователе
         * @param {string} data.username Имя пользователя (email)
         * @param {string} data.password Пароль пользователя (не менее 8 символов, 1 буква верхнего регистра, 1 цифра)
         * @returns {Promise}
         */
        login: function (data) {
            return ns.point("api_login", data);
        },
        /**
         * Стирает авторизационные куки
         * @return {Promise}
         */
        logout: function () {
            return ns.point("api_logout", null, "GET");
        },
        get_bases: function (data) {
            return ns.point("api_getdbs", data);
        },
		
    }; // ns
    return ns;
})();
