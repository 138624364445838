import React from 'react';
import { Func } from "./common/func";
import { API } from "./API/api";


class HeaderLine extends React.Component {

  constructor(props) {
    super(props);
	var base_id = parseInt(this.props.base_id);
	var sec_id  = parseInt(this.props.sec_id);
	var obj_id  = parseInt(this.props.obj_id);
	var link_id  = parseInt(this.props.link_id);
	//console.log({base_id, sec_id, obj_id, link_id});

    var cfz = Func.get_sess_id();
	//console.log({cfz});

	this.state = {
		sess_id: cfz,
		base_id: base_id,
		sec_id:  sec_id,
		obj_id:  obj_id,
		link_id: link_id,
		dbName: null,
		secName: null,
		objName: null
	};

  } // constructor

componentDidMount(){
	if(parseInt(this.state.base_id) !== 0){
		this.getNameKB(parseInt(this.state.base_id));
	}
	if(parseInt(this.state.sec_id) !== 0){
		this.getNameSec(parseInt(this.state.sec_id));
	}
	if(parseInt(this.state.obj_id) !== 0){
		this.getNameObj(parseInt(this.state.obj_id));
	}
} // componentDidMount

getNameKB = (kbID) => {
	return API.point('api_get_name_byid', {"sess_id": this.state.sess_id, "nameEntity": "knowledgebases","ent_id": kbID}, "POST")
		.then(res => {
			this.setState({
				dbName: res
			});
		})
		.catch(err => {
			this.setState({ error: err });
		});
};

getNameSec = (secID) => {
	return API.point('api_get_name_byid', {"sess_id": this.state.sess_id, "nameEntity": "sections","ent_id": secID}, "POST")
		.then(res => {
			this.setState({
				secName: res
			});
		})
		.catch(err => {
			this.setState({ error: err });
		});
};

getNameObj = (objID) => {
	return API.point('api_get_name_byid', {"sess_id": this.state.sess_id, "nameEntity": "objects","ent_id": objID}, "POST")
		.then(res => {
			this.setState({
				objName: res
			});
		})
		.catch(err => {
			this.setState({ error: err });
		});
}; 
  
  render () {
	const dbName = this.state.dbName;
	const secName = this.state.secName;
	const objName = this.state.objName;
	//console.log({"nameKB":dbName});

    return (
		<section id="page-title" class="page-title-mini">

			<div class="container clearfix">
				<h1><a href="/">Редактор Базы знаний</a></h1>
				<span>Панель администратора</span>
				<ol class="breadcrumb">
				
				{dbName && 
						<li class="breadcrumb-item"><a href={"/sections/"+this.state.base_id+"/"}>{dbName}</a></li>
				}
				{secName && 
						<li class="breadcrumb-item"><a href={"/objects/"+this.state.base_id+"/"+this.state.sec_id+"/"}>{secName}</a></li>
				}
				{objName && 
						<li class="breadcrumb-item"><a href={"/links/"+this.state.base_id+"/"+this.state.sec_id+"/"+this.state.obj_id+"/"}>{objName}</a></li>
				}
					
				</ol>
			</div>

		</section>
    );
  }
} // class HeaderLine

export default HeaderLine;