import React from 'react';
//import { Func } from "./common/func";
import { API } from "./API/api";
import InputField from "./InputField";
import $ from "jquery";

class ControlSection extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			sec_ID_to_edit: null,
			edit: false,
		};
		this.addSection = this.addSection.bind(this);
	} // constructor
	addSection(kbID, secID, flagEdit){
		this.setState({
			sec_ID_to_edit: secID,
			edit: flagEdit
		});
	} // addSection
	componentWillUpdate(newProps){
		console.log({"***** CLICK SECTION ": newProps.sec_id});
	}
	render (){
		return (
			<>
				<div>
				<span style={{"fontStyle":"italic", "fontSize":"130%"}}>{this.props.sec_name}</span> {/*[{this.props.sec_id}]*/}: &nbsp;
					<i title="Добавить раздел в текущую БЗ" onClick={() => this.addSection(this.props.base_id, 0, true)} className="icon-line-square-plus" style={{"color":"#59BA41", "cursor":"pointer"}}></i>&nbsp;&nbsp;
					<i title="Редактировать текущий раздел" onClick={() => this.addSection(this.props.base_id, this.props.sec_id, true)} className="icon-pencil" style={{"color":"#59BA41", "cursor":"pointer"}}></i>&nbsp;&nbsp;
					<i title="Удалить текущий раздел" className="icon-line-square-cross" style={{"color":"#C02942", "cursor":"pointer"}}></i>
				</div>
				<FormElementSec
					sess_id    = {this.props.sess_id}
					baseID     = {this.props.base_id}
					base_name  = {this.props.base_name}
					secID      = {this.state.sec_ID_to_edit}
					sec_name   = {this.props.sec_name}
					edit       = {this.state.edit}
					addSection = {this.addSection}
				/>
			</>
		);
	}
} // ControlSection

export default ControlSection;

class FormElementSec extends React.Component {
	constructor(props) {
		super(props);
		this.closeForm = this.closeForm.bind(this);
		this._onSubmit = this._onSubmit.bind(this);
		this.createSec = this.createSec.bind(this);
		this.changeNameSec = this.changeNameSec.bind(this);
	} // constructor
	componentWillReceiveProps(newProps){
		//console.log({"newPropw_formElSec": newProps.edit});
		if(newProps.edit){
			//alert('render '+newProps.secID);
			//this.getNameSec(newProps.secID);
			this.fillFields(newProps.secID);
		}
	}
	fillFields(sectionID){
		if(sectionID === null || sectionID === undefined){
			return;
		}
		sectionID = parseInt(sectionID);
		if(sectionID === 0){
			$('#newSec-header').html('Создание нового раздела');
			$('#buttonSec').html('Создать раздел');
		}else{
			$('#newSec-header').html('Редактировать раздел #'+sectionID);
			$('#buttonSec').html('Сохранить раздел');
		}
		$('#createSec_forma').modal('show');
	} // fillFields
	closeForm(){
		this.props.addSection(this.props.baseID, this.props.secID, false);
	}
	createSec(kbId, nameSec){
		try {
			API.point('api_create_sec', { "sess_id": this.props.sess_id, "kbId": kbId, "nameSec": nameSec}, "POST");
		} catch (err) {
			this.setState({ error: err });
		}
	} // createSec
	changeNameSec(secID, nameSec){
		try {
			API.point('api_edit_sec', { "sess_id": this.props.sess_id, "secID": secID, "nameSec": nameSec}, "POST");
		} catch (err) {
			this.setState({ error: err });
		}
	} // changeNameSec
	_onSubmit(e){
		e.preventDefault();
		//var fd = $('#f_nsc').serialize();
		//alert(fd);

		var kbId    = parseInt($('#kbID').val());
		var secID   = parseInt($('#secID_sec').val());
		var secName = $('#nameSec').val();

		//alert(''+kbId+' -> '+secID+' -> '+secName);

		if(secID === 0){
			//alert('создаём');
			this.createSec(kbId, secName);
		}else{
			//alert('редактируем');
			this.changeNameSec(secID, secName);
		}
		document.location.reload();
	} // _onSubmit
	render () {
		return (
			<div>
				<div id="createSec_forma" className="modal fade">
					<div className="modal-dialog">
						<div className="modal-content">
							{/*<!-- Заголовок модального окна -->*/}
							<div className="modal-header">
								<h4 className="modal-title" id="newSec-header">Создание раздела</h4>
								<div className="text-right" style={{"paddingRight": "7px"}}>
									<button onClick={this.closeForm} type="button" className="close btn closeModalButton" data-dismiss="modal" aria-hidden="true">×</button>
								</div>
							</div>
							{/*<!-- Основное содержимое модального окна -->*/}
							<div id="newSec-content" className="modal-body">
								<form id="f_nsc" name="f_nsc" onSubmit={this._onSubmit}>
									<input type="hidden" id="kbID" name="kbID" value={this.props.baseID} />
									<input type="hidden" id="secID_sec" name="secID_sec" value={this.props.secID} />
									<div className="form-group">
										<label for="url">Имя базы:</label>
										<p id="nameBase">{this.props.base_name}</p>
									</div>
									<div className="form-group">
										<label for="url">Имя раздела:</label>
										<InputField
											type="text"
											className="form-control"
											id="nameSec"
											name="nameSec"
											placeholder="Имя раздела"
											value={this.props.sec_name}
										/>
									</div>
									<button type="submit" className="btn btn-primary" id="buttonSec">Создать раздел</button>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
} // FormElementSec