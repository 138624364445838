import React from 'react';
//import { Func } from "./common/func";
import { API } from "./API/api";
import $ from "jquery";

class LinksList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: false,
			data_out: false,
			isLoaded: false,
			flagEditLink: false,
			LinkID_edit: null,
			directLink: null
		};
		this._onClick = this._onClick.bind(this);
		this.addLink = this.addLink.bind(this);
	} // constructor
	componentDidMount(){
		//console.log({"links did mount": this.props.objID});
		this.setState({
			data: false,
			data_out: false,
			isLoaded: false
		});
		this.fetchData(false);
		this.fetchData_out(false);
	}
	componentDidUpdate(prevProps){
		if(this.props.obj_id !== prevProps.obj_id) {
			//console.log({"links did update": this.props.obj_id, "prev prop": prevProps.obj_id});
			this.setState({
				data: false,
				data_out: false,
				isLoaded: false
			});
		}
		if(this.props.flagVis !== prevProps.flagVis){
			//console.log({"links did upd flagVis": this.props.flagVis, "prev prop": prevProps.flagVis});
		}
	}
	componentWillReceiveProps(newProps){
		//console.log({"links will reciev": this.props.objID_link, "new prop": newProps.objID_link, "flagVis": this.props.flagVis});
		//this.fetchData(newProps);
		//this.fetchData_out(newProps);
		if(this.props.flagVis){
			this.fetchData(true);
			this.fetchData_out(true);
			//this.props.showLinks(newProps.objID_link, false);
		}else{
			this.fetchData(false);
			this.fetchData_out(false);
			//this.props.showLinks(newProps.objID_link, true);
		}
	} // componentWillReceiveProps
	_onClick(){
		//this.props.clickLinks(true);
		if(!this.state.isLoaded){
			this.fetchData(true);
			this.fetchData_out(true);
			this.setState({
				isLoaded: true
			});
		}else{
			this.fetchData(false);
			this.fetchData_out(false);
			this.setState({
				isLoaded: false
			});
		}
	} // _onClick
    fetchData = (f) => {
		//console.log({"links dwn": this.props.objID});
		if(!f){
			this.setState({
				data: false
			});
			return false;
		}
        return API.point('api_getlinks_in', {"sess_id": this.props.sess_id, "curObj": this.props.obj_id}, "POST")
            .then(res => {
                this.setState({
                    data: res,
                    isLoaded: true
                });
            })
            .catch(err => {
                this.setState({ error: err });
            });
    };
    fetchData_out = (f) => {
		//console.log({"links dwn": this.props.obj_id});
		if(!f){
			this.setState({
				data_out: false
			});
			return false;
		}
        return API.point('api_getlinks_out', {"sess_id": this.props.sess_id, "curObj": this.props.obj_id}, "POST")
            .then(res => {
                this.setState({
                    data_out: res,
                    isLoaded: true
                });
            })
            .catch(err => {
                this.setState({ error: err });
            });
    };
	addLink(objID, objName, LinkID, LinkItem, flagEdit, direct){
		this.setState({
			flagEditLink: flagEdit,
			LinkID_edit: LinkID,
			directLink: direct,
			obj_id: objID,
			obj_name: objName
		});
	}
	render(){
		const data     = this.state.data;
		const data_out = this.state.data_out;

		const arrLinkTypes = new Map([
			[1, 'RS'],
			[2, 'TRA'],
			[3, 'S']
		]);

		return(
			<>
			{/*this.props.obj_id} - {this.props.obj_name*/}
			<div className="col_half">
			{data &&
				(<div className="links_header">
					↙ входящие &nbsp;&nbsp;
					<i onClick={() => this.addLink(this.props.obj_id, this.props.obj_name, 0, {}, true, "in")} class="icon-line-square-plus" style={{"cursor":"pointer"}} ></i>
				</div>)
			}
			{data && 
				Object.values(data).sort((a, b) => a.id - b.id).map(item => (
					<div>
						<b>{arrLinkTypes.get(parseInt(item.connectionType))}</b> &nbsp;
						{/*item.id*/} <span onClick={() => this.addLink(this.props.obj_id, this.props.obj_name, item.id, {}, true, "in")} style={{"cursor":"pointer", "textDecoration":"underline"}}>{item.contr_name}</span> <span style={{"color":"#ccc"}}>#{item.contr}</span>
					</div>
				))
			}
			{!data &&
				<></>
			}
			</div>
			<div className="col_half col_last">
			{data_out &&
				(<div className="links_header">
					↗ исходящие &nbsp;&nbsp;
					<i onClick={() => this.addLink(this.props.obj_id, this.props.obj_name, 0, {}, true, "out")} class="icon-line-square-plus" style={{"cursor":"pointer"}} ></i>
				</div>)
			}
			{data_out && 
				Object.values(data_out).sort((a, b) => a.id - b.id).map(item => (
					<div>
						<b>{arrLinkTypes.get(parseInt(item.connectionType))}</b> &nbsp;
						{/*item.id*/} <span onClick={() => this.addLink(this.props.obj_id, this.props.obj_name, item.id, {}, true, "out")} style={{"cursor":"pointer", "textDecoration":"underline"}}>{item.contr_name}</span> <span style={{"color":"#ccc"}}>#{item.contr}</span>
					</div>
				))
			}
			{!data_out &&
				<></>
			}
			</div>

			<FormElementLink
				sess_id     = {this.props.sess_id}
				obj_id       = {this.props.obj_id}
				obj_name     = {this.props.obj_name}
				flagEditLink = {this.state.flagEditLink}
				LinkID_edit = {this.state.LinkID_edit}
				directLink  = {this.state.directLink}
				addLink     = {this.addLink}
				objItemArray = {this.props.objItemArray}
				base_name    = {this.props.base_name}
				sec_name = {this.props.sec_name}
				sec_list     = {this.props.sec_list}
				sec_id     = {this.props.sec_id}
			/>

			</>
		);
	}
} // LinksList

export default LinksList;

class FormElementLink extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			obj_id: this.props.obj_id,
			strCurrLinkName: '',
			selectedSection: 0,
			data_nodes: undefined,
			selectedNode: 0
		};
		this.closeForm     = this.closeForm.bind(this);
		this.handleSection = this.handleSection.bind(this);
		this.handleNode    = this.handleNode.bind(this);
	} // constructor
	componentWillReceiveProps(newProps, newState){
		console.log({"*** form Link props": newProps});
		this.setState({
			strCurrLinkName: newProps.objItemArray.name
		});
		if(newProps.flagEditLink){		
			this.fill_Labels(newProps);
		}
	} // componentWillReceiveProps

	fill_Labels(newProps){
		var LinkID = newProps.LinkID_edit;
		var direct = newProps.directLink;
		if(LinkID === null || LinkID === undefined){
			return;
		}
		var schei = "входящей";
		var ot_objecta = "от объекта ...";
		if(direct === "out"){
			schei = "исходящей";
			ot_objecta = "к объекту ...";
		}
		LinkID = parseInt(LinkID);
		if(LinkID === 0){
			$('#newLink-header').html('Создание '+schei+' связи');
			$('#buttonLink').html('Создать связь');
		}else{
			$('#newLink-header').html('Ред. '+schei+' связи #'+LinkID);
			$('#buttonLink').html('Сохранить связь');
		}
		$('#ot_objecta').html(ot_objecta);
		$('#createLink_forma').modal('show');
	} // fill_Labels
	closeForm(){
		this.props.addLink(this.props.obj_id, this.props.obj_name, this.props.LinkID_edit, {}, false);
	}
	addLink(obj_id, obj_name, linkID, itemLink, flagEdit){
		this.props.addLink(obj_id, obj_name, linkID, itemLink, flagEdit);
	}
	handleNode(e){
		this.setState({
			selectedNode: e.target.value
		});
	}
	handleSection(e){
		//console.log({"sel val section": e.target.value});
		try {
			const res = API.point('api_getobjects', { "sess_id": this.props.sess_id, "sec_id": e.target.value }, "POST");
			if(res !== undefined && res !== false){
				res.then(
					result => this.setState({
						selectedSection: e.target.value,
						data_nodes: result
					}),
					error  => alert("err")
				);
			}
			this.setState({
				selectedSection: e.target.value,
				data_nodes: res
			});
		} catch (err) {
			this.setState({ error: err });
		}
	}
	_onSubmit(e){
		e.preventDefault();
		var fd = $('#f_nlink').serialize();
		alert(fd);
		/*
		var ObjID = parseInt($('#ObjID').val());
		if(ObjID === 0 || isNaN(ObjID) || ObjID === null || ObjID === undefined){
			ObjID = 0;
		}
		var dataRequest = {
			"ObjID":     ObjID,
			"secID_Obj": parseInt($('#secID_Obj').val()),
			"nameObj":   $('#nameObj').val(),
			"typeObj":   parseInt($('#typeObj').val()),
			"hypo":      ($('#hypo').val()) ? 1 : 0,
			"vis":       ($('#vis').val())  ? 1 : 0,
			"comment":   $('#comment').val(),
			"sess_id":   $('#sess_id').val()
		}
		*/
	} // _onSubmit

	render () {

		//const object_name = (this.props.objItemArray) ? this.props.objItemArray.name : '' ;
		//console.log({"strCurrLinkName":this.state.strCurrLinkName});

		const sec_list   = this.props.sec_list;
		const data_nodes = this.state.data_nodes;
		console.log({"sec_list": sec_list, "data_nodes": data_nodes});

		return (
			<div id="createLink_forma" className="modal fade">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
						<h4 className="modal-title" id="newLink-header">Создание связи</h4>
						<div className="text-right" style={{"paddingRight": "7px"}}>
							<button onClick={this.closeForm} type="button" className="close btn closeModalButton" data-dismiss="modal" aria-hidden="true">×</button>
						</div>
						</div>
						<div id="newLink-content" className="modal-body">
							<form id="f_nlink" onSubmit={this._onSubmit}>
								<div className="form-group">
									<b id="nameBase_Link">{this.props.base_name}</b> :: <i id="nameSec_Link">{this.props.sec_name}</i> :: <b id="nameObj_Link">{this.props.obj_name}</b> [{this.props.obj_id}]
									<input type="hidden" id="LinkID"  value="0" />
									<input type="hidden" id="ObjID_Link" value="" />
								</div>
								<div className="form-group">
									<label for="url">Тип связи:</label>
									<div>
										<select
											className="form-control"
											id="TypeID_Link"
											style={{"width":"100%"}}>
											<option value={0}>- тип связи -</option>
											<option value={1}>RS</option>
											<option value={2}>TRA</option>
											<option value={3}>S</option>
										</select>
									</div>
								</div>
								<div className="form-group">
									<label for="url" id="ot_objecta"></label>
									<div style={{"marginBottom":"3px"}}>
										<select
												className="form-control"
												id="toSecID"
												style={{"width":"100%"}}
												onChange={this.handleSection}
												defaultValue={this.state.selectedSection}>
											<option value={0}>- выберите раздел -</option>
											{sec_list &&
												Object.values(sec_list).sort((a, b) => a.id - b.id).map(item => (
													<option value={item.id}>{item.name}</option>
												))
											}
										</select>
									</div>
									<div>
										<select
												className="form-control"
												id="toObjID"
												style={{"width":"100%"}}
												onChange={this.handleNode}
												defaultValue={this.state.selectedNode}>
											<option value={0}>- выберите объект -</option>
											{data_nodes &&
												Object.values(data_nodes).map(itm => (
													<option value={itm.id}>{itm.name}</option>
												))
											}
										</select>
									</div>
								</div>
								<button type="submit" className="btn btn-primary" id="buttonLink">Создать связь</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		); // return
	} // render

} // FormElementLink