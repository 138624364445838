import React from 'react';
import '../App.css';
import { Func } from "./common/func";
import { API } from "./API/api";
import HeaderLine from "./HeaderLine";
import $ from "jquery";

const arrParams = [
	"age",
	"gender",
	"hypertonic",
	"hypoton",
	"ads_upper_usual",
	"ads_down_usual",
	"diabetes",
	"merc_aritm",
	"headache_frequency",
	"headache_force",
	"head_pain",
	"headache_meds",
	"migren",
	"HronPain",
	"bad_sleep",
	"sleepless",
	"smoking_status",
	"smoking",
	"cigaretts_per_day",
	"alco_now",
	"alco",
	"move_activ",
	"move_problem",
	"move_duration",
	"activity_status",
	"work_night_daily",
	"work_noise_strain",
	"hight_job_perdomance",
	"social_net",
	"IDLL3H",
	"HMLN",
	"fruit_less",
	"vegan_less",
	"food_style",
	"freq_eat",
	"food_salt",
	"pulse_person",
	"height",
	"weight",
	"OTcm",
	"OBcm",
	"citokeens",
	"TotalHol",
	"blood_clqotting",
	"health_status",
	"health_status_equal",
	"education",
	"moneylvl",
	"stress",
	"alone_fils",
	"relgion",
	"PEICYO",
	"EEICYO",
	"SEICYO",
	"TPICYO",
	"m_dead",
	"RBPIOY",
	"Ctastroph_old",
	"PEIOYY",
	"EEIOYY",
	"SEIOYY"
];

const sess_id = Func.get_sess_id();

class TestKB extends React.Component {
	constructor(props) {
		super(props);
		document.title = "Тест базы знаний";
		//var cfz = Func.get_sess_id();
		console.log({"test kb cfz":sess_id});

		this.clickAddParametr = this.clickAddParametr.bind(this);
		//this.handleParam = this.handleParam.bind(this);
		//this.fetchData = this.fetchData.bind(this);
	}
	clickAddParametr(){
		var new_elements = $(".key_val").clone();
		new_elements.removeClass("key_val");
		new_elements.find("input").val('');
		new_elements.appendTo("#ftest");
	}
	/*
	handleParam = (e) =>{
		this.setState({objectName: e.target.value});
	}
	*/
	_onSubmit(e){
		console.log({"onSubmit sess_id":sess_id});
		e = window.event || e;
		e.preventDefault();
		//var fd = $('#ftest').serialize();
		var fd = $('#ftest').serializeArray();
		//console.log(fd);

		//var counter = 1;

		//var form_vals = [];
		var obj_params = {}
		for(var item in fd){
			var js_line = fd[item];
			//console.log(fd[item]);

			if(js_line['name'] === 'key'){
				var current_key = js_line['value'];
			}
			if(js_line['name'] === 'val'){
				
				obj_params[current_key] = js_line['value'];
				//form_vals.push(obj);
			}
			//counter = counter + 1;
		}
		
		//var jsParams = JSON.stringify(obj_params);
		//console.log(jsParams);
		var obj_final = {};
		obj_final["status"] = parseInt($("#status").val());
		obj_final["system"] = $("#system").val();
		obj_final["result"] = obj_params;

		console.log({"obj_final":obj_final});

		var res = null;
		try {
			res = API.point('api_test_kb', { "sess_id": sess_id, "req_body": JSON.stringify(obj_final) }, "POST");
			res.then(
				result => {
					console.log({"res test kb": result.system, "risks": result.risks});
					var res_str = '';
					res_str = res_str + '<h5>'+ result.system +'</h5><table class="table table-stripted table-hover">';
					result.risks.forEach(function(item){
						console.log({"item": item});
						console.log({"item name": item['name']});
						res_str = res_str + '<tr><td><b>'+item['name']+' ['+item['rang']+']</b></td></tr>';
						
						for (var key in item.args) {
							console.log(key, item.args[key])
							res_str = res_str + '<tr><td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'+item.args[key]+'</td></tr>';
						}
					}); // по рискам

					res_str = res_str + '</table>';
					$('#query_result').html(res_str);
				},
				error =>  {}
			);
		} catch (err) {
			var error = err;
		}
		
	} // _onSubmit
	render () {
		return (
			<>
				<HeaderLine
					base_id = {0}
					sec_id  = {0}
					obj_id  = {0}
					link_id = {0}
				/>
			

				<section id="content">
					<div className="content-wrap">
						<div className="container clearfix">
							<div class="col_half">
								<h5>Параметры запроса</h5>
								<div className="form-group">
									<label for="status">Статус:</label>
									<input className="form-control" id="status" name="status" />
								</div>
								<div className="form-group">
									<label for="system">Система:</label>
									<select name="system" id="system" className="form-control">
										<option value="insult">Инсульт</option>
										<option value="depression">Депрессия</option>
										<option value="hypertension">Артериальная гипертензия</option>
										<option value="infarct">Инфаркт</option>
									</select>
								</div>
								<form name="ftest" id="ftest" onSubmit={this._onSubmit}>
									<div className="key_val form-group">
										<div className="col_half">
											<select name="key" className="form-control">
												<option value="">- выберите параметр -</option>
												{Object.values(arrParams).map(param => (
													<option value={param}>{param}</option>
												))}
											</select>
										</div>
										<div className="col_half col_last">
											<input className="form-control" name="val" />
										</div>
									</div>
								</form>
								<span onClick={this.clickAddParametr} style={{"cursor": "pointer"}}>[+] <span style={{"textDecoration": "underline"}}>Добавить параметр</span></span>
								<div className="text-right">
									<input className="btn btn-primary" type="submit" value="Отправить запрос" onClick={this._onSubmit} />
								</div>
							</div>

							<div class="col_half col_last">
								<h5>Ответ</h5>
								<div id="query_result"></div>
							</div>

							<div class="clear"></div>
						</div>
					</div>
				</section>


			</>
		);
	}
} // class TestKB

export default TestKB;


