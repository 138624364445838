//import logo from './logo.svg';
import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
//import AuthForm from "./components/AuthForm";
import KBases   from "./components/KBases";
//import Sections from "./components/Sections";
//import Objects  from "./components/Objects";
//import Links    from "./components/Links";

//import KBases_new from "./components/KBases_new";
import NewDesign  from "./components/NewDesign";
import KB         from "./components/KB";
import Exp_1      from "./exp/Exp_1";
import KBasesNew   from "./components/KBasesNew";
import TestKB   from "./components/TestKB";

//import './App.css';
import { AuthRouting } from "./components/AuthRouting";

class App extends React.Component {
  constructor(props) {
    super(props);
    document.title = "Редактор Базы знаний";
	this.state = { loading: true };
  }
  
	componentDidMount = () => {
	  this.setState({ loading: false });
	};
  
  render () {

    //const { history } = this.props
	if (this.state.loading) return <React.Fragment />;
	return (
		<BrowserRouter>
		<Switch>
			<Route
				exact
				path="/"
				render={() => <Redirect to="/auth/start" />}
			/>
			<Route path="/auth" component={AuthRouting} />
			<Route path='/bases' component={KBases} />
			<Route path='/bases-new' component={KBasesNew} />

			{/*<Route path='/bases_new' component={KBases_new} />*/}
			<Route path='/new' component={NewDesign} />
			<Route path='/testkb' component={TestKB} />

			<Route path='/kb/:base_id' component={KB} />

			<Route exact path='/exp/1' component={Exp_1} />
			{/*<Route path='/sections/:base_id' component={Sections} />
			<Route path='/objects/:base_id/:sec_id' component={Objects} />
			<Route path='/links/:base_id/:sec_id/:obj_id' component={Links} />*/}

		</Switch>
		</BrowserRouter>
	);

  }
} // class App

export default App;
