import React from 'react';
//import '../App.css';
import { Func } from "./common/func";
import HeaderLine from "./HeaderLine";

class HeaderSection extends React.Component {
	render (){
		return (
			<div>
				<div>
					<div style={{ "float": "left" }}>
						<div className="fancy-title title-double-border">
							<h5>Объекты раздела</h5>
						</div>
					</div>
					<div style={{ "float": "right" }}>
						операции с разделом:
						<span className="button button-3d button-mini button-rounded button-green" style={{ "color": "#fff !important" }}>Ред.</span>
						<span className="button button-3d button-mini button-rounded button-red" style={{ "color": "#fff !important" }}>Уд.</span>
					</div>
				</div>
				<div style={{ "clear": "both" }}></div>
			</div>
		);
	}
} // HeaderSection

class ObjectStr extends React.Component {
	render (){
		return (
			<div className="toggle">
				<div className="togglet">
					<i className="toggle-closed icon-ok-circle"></i>
					<i className="toggle-open icon-remove-circle"></i>
					{this.props.ObjectTitle}
				</div>
				<div className="togglec">
					<table className="table table-striped table-hover">
						<tr>
							<th style={{width: "25px"}}>#ID</th>
							<th>Тип</th>
							<th>Направление</th>
							<th>Контр-объект</th>
							<th style={{"textAlign":"center"}}>Действия</th>
						</tr>
						<tr>
							<td style={{width: "25px"}}>16</td>
							<td>RS</td>
							<td>↗</td>
							<td>
								Отказ от курения<br />
								<span style={{"color":"#999"}}>#100</span>
							</td>
							<td style={{"textAlign":"center"}}>
								<i class="icon-pencil" style={{"color":"#59BA41"}}></i>&nbsp;&nbsp;&nbsp;
								<i class="icon-line-delete" style={{"color":"#C02942"}}></i>
							</td>
						</tr>
						<tr>
							<td style={{width: "25px"}}>16</td>
							<td>TRA</td>
							<td>↙</td>
							<td>
								Курит в настоящее время от 5 до 10 сигарет в день со стажем до 5 лет<br />
								<span style={{"color":"#999"}}>#129</span>
							</td>
							<td style={{"textAlign":"center"}}>
								<i class="icon-pencil" style={{"color":"#59BA41"}}></i>&nbsp;&nbsp;&nbsp;
								<i class="icon-line-delete" style={{"color":"#C02942"}}></i>
							</td>
						</tr>
					</table>
				</div>
			</div>
		);
	}
} // ObjectStr

class NewDesign extends React.Component {
	constructor(props) {
		super(props);
		document.title = "Новый дизайн";
		Func.testAuth('');
	}

	render () {
		return (
			<div>
				<HeaderLine
					base_id = {0}
					sec_id  = {0}
					obj_id  = {0}
					link_id = {0}
				/>

				<section id="content">
					<div className="content-wrap">
						<div className="container clearfix">
							<h4>Risk Level Assesment Knowledge Base</h4>

							<div className="tabs side-tabs tabs-bordered clearfix" id="tab-5">

								<ul className="tab-nav clearfix">
									<li><a href="#tabs-17">Характеристики человека</a></li>
									<li><a href="#tabs-18">Стресс и депрессия</a></li>
									<li><a href="#tabs-19">Образ жизни</a></li>
									<li><a href="#tabs-20">Молекулярно-генетический анализ</a></li>

									<li><a href="#tabs-21">Семейный анамнез</a></li>
									<li><a href="#tabs-22">Менопауза</a></li>
									<li><a href="#tabs-23">Питание</a></li>
									<li><a href="#tabs-24">Неврологические нарушения</a></li>
								</ul>

								<div className="tab-container">

									<div className="tab-content clearfix" id="tabs-17">
										<HeaderSection />

										<ObjectStr ObjectTitle = "Мужской пол" />
										<ObjectStr ObjectTitle = "Возраст 45-54 лет" />
										<ObjectStr ObjectTitle = "Возраст 80 лет и старше" />
										<ObjectStr ObjectTitle = "Женский пол" />
										<ObjectStr ObjectTitle = "Возраст 40-44 лет" />
										<ObjectStr ObjectTitle = "Возраст 55-59 лет" />
										<ObjectStr ObjectTitle = "Возраст 24-39 лет" />
										<ObjectStr ObjectTitle = "Возраст 60-79 лет" />
										<ObjectStr ObjectTitle = "Возраст 18-23 лет" />

									</div>
									<div className="tab-content clearfix" id="tabs-18">
										<HeaderSection />

										<ObjectStr ObjectTitle = "Субклиническая тревога" />
										<ObjectStr ObjectTitle = "Субклиническая депрессия" />
										<ObjectStr ObjectTitle = "Клинически выраженная тревога" />
										<ObjectStr ObjectTitle = "Клинически выраженная депрессия" />
										<ObjectStr ObjectTitle = "Высокий уровень стресса на протяжении последнего месяца" />
										<ObjectStr ObjectTitle = "Очень высокий уровень стресса на протяжении последнего месяца" />
									</div>
									<div className="tab-content clearfix" id="tabs-19">
										<HeaderSection />
										<ObjectStr ObjectTitle = "Субклиническая тревога" />
										<ObjectStr ObjectTitle = "Субклиническая депрессия" />
										<ObjectStr ObjectTitle = "Клинически выраженная тревога" />
										<ObjectStr ObjectTitle = "Клинически выраженная депрессия" />
										<ObjectStr ObjectTitle = "Высокий уровень стресса на протяжении последнего месяца" />
										<ObjectStr ObjectTitle = "Очень высокий уровень стресса на протяжении последнего месяца" />
									</div>
									<div className="tab-content clearfix" id="tabs-20">
										<HeaderSection />
										<ObjectStr ObjectTitle = "Субклиническая тревога" />
										<ObjectStr ObjectTitle = "Субклиническая депрессия" />
										<ObjectStr ObjectTitle = "Клинически выраженная тревога" />
										<ObjectStr ObjectTitle = "Клинически выраженная депрессия" />
										<ObjectStr ObjectTitle = "Высокий уровень стресса на протяжении последнего месяца" />
										<ObjectStr ObjectTitle = "Очень высокий уровень стресса на протяжении последнего месяца" />
									</div>

									<div className="tab-content clearfix" id="tabs-21">
										<HeaderSection />
										<ObjectStr ObjectTitle = "Субклиническая тревога" />
										<ObjectStr ObjectTitle = "Субклиническая депрессия" />
										<ObjectStr ObjectTitle = "Клинически выраженная тревога" />
										<ObjectStr ObjectTitle = "Клинически выраженная депрессия" />
										<ObjectStr ObjectTitle = "Высокий уровень стресса на протяжении последнего месяца" />
										<ObjectStr ObjectTitle = "Очень высокий уровень стресса на протяжении последнего месяца" />
									</div>
									<div className="tab-content clearfix" id="tabs-22">
										<HeaderSection />
										<ObjectStr ObjectTitle = "Субклиническая тревога" />
										<ObjectStr ObjectTitle = "Субклиническая депрессия" />
										<ObjectStr ObjectTitle = "Клинически выраженная тревога" />
										<ObjectStr ObjectTitle = "Клинически выраженная депрессия" />
										<ObjectStr ObjectTitle = "Высокий уровень стресса на протяжении последнего месяца" />
										<ObjectStr ObjectTitle = "Очень высокий уровень стресса на протяжении последнего месяца" />
									</div>
									<div className="tab-content clearfix" id="tabs-23">
										<HeaderSection />
										<ObjectStr ObjectTitle = "Субклиническая тревога" />
										<ObjectStr ObjectTitle = "Субклиническая депрессия" />
										<ObjectStr ObjectTitle = "Клинически выраженная тревога" />
										<ObjectStr ObjectTitle = "Клинически выраженная депрессия" />
										<ObjectStr ObjectTitle = "Высокий уровень стресса на протяжении последнего месяца" />
										<ObjectStr ObjectTitle = "Очень высокий уровень стресса на протяжении последнего месяца" />
									</div>
									<div className="tab-content clearfix" id="tabs-24">
										<HeaderSection />
										<ObjectStr ObjectTitle = "Субклиническая тревога" />
										<ObjectStr ObjectTitle = "Субклиническая депрессия" />
										<ObjectStr ObjectTitle = "Клинически выраженная тревога" />
										<ObjectStr ObjectTitle = "Клинически выраженная депрессия" />
										<ObjectStr ObjectTitle = "Высокий уровень стресса на протяжении последнего месяца" />
										<ObjectStr ObjectTitle = "Очень высокий уровень стресса на протяжении последнего месяца" />
									</div>

								</div>

							</div>
						</div>
					</div>
				</section>
			</div>
		);
	}
} // class NewDesign

export default NewDesign;
