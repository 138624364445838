//import logo from './logo.svg';
import React from 'react';
import '../App.css';
import { API } from "./API/api";
import { Func } from "./common/func";
import InputField from "./InputField";

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
	
    this.state = {
      loading: true,
    };
    document.title = "Редактор Базы знаний LoginForm";
  }

  _onClick(){
    var log = document.getElementById('login').value;
    var psw = document.getElementById('psw').value;

    //alert(log + ' - ' + psw);

    API.login({"login": log, "psw": psw})
      .then((res, x) => {
		//alert('sess_id: '+res);
        //Успешно! получили sess_id
        let sess_id = res;

		// сохраняем sess_id в куки на сутки
		var expireAt = new Date; 
		expireAt.setDate(expireAt.getDate() + 1);
		Func.setCookie('sess_id', sess_id, expireAt.toGMTString(), '/', 'healthvisio.ru', '');
        
        // переадресовываем на профиль
        document.location.href = '/bases';
    })
    .catch(e => {
        // сбой
        alert('error');
    });
    
  } // _onClick

  render(){
    const { history } = this.props
      return(
	  
		<section id="content">
			<div className="content-wrap">
				<div className="container clearfix">
			
					<div className="card divcenter center" style={{maxWidth: "500px"}}>
						<div className="card-body">

								<div className="col_full">
									<label className="t400">Логин:</label>
									  <InputField
										type="text"
										id="login"
										name="login"
										placeholder="введите логин" 
										className="form-control"
									  />
								</div>

								<div className="col_full">
									<label className="t400">Пароль:</label>
									<InputField
										type="password"
										id="psw"
										name="psw"
										placeholder="введите пароль" 
										className="form-control"
									  />
								</div>

								<div className="col_full nobottommargin">
									<button className="button button-rounded t400 nomargin" onClick={this._onClick}>Войти</button>
								</div>


						</div>
					</div>
					<div className="clear"></div>
				</div>
			</div>
		</section>
	  
      );
  }
} // LoginForm

class AuthForm extends React.Component {
  constructor(props) {
    super(props);
	//Func.testAuth("/bases");
    document.title = "Редактор Базы знаний";
  }

  render () {
    return (        
		<div>
          <LoginForm />
		</div>
    );

  }
} // class AuthForm

export default AuthForm;
