import React from 'react';
//import { Func } from "./common/func";
import { API } from "./API/api";

class SectionItem extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			secName: this.props.name,
			isLoaded: false, 
		};
		this._onClick = this._onClick.bind(this);
	}
	componentDidMount_111(){
		this.getNameSec(parseInt(this.props.id));
	}
	componentDidUpdate(newProps){
		//console.log({"Sec Item new props": newProps, "Sec Item prev props": this.props});
	}
	getNameSec = (secID) => {
		return API.point('api_get_name_byid', {"sess_id": this.props.sess_id, "nameEntity": "sections","ent_id": secID}, "POST")
			.then(res => {
				this.setState({
					secName: res
				});
			})
			.catch(err => {
				this.setState({ error: err });
			});
	};
	_onClick(id, name){
		this.props.selectSection(id, name);
	}
	render (){
		const secName = this.state.secName;
		var flag = (secName !== '') ? true : false;

		return (
			<li onClick={() => this._onClick(this.props.id, secName)} style={{"cursor":"pointer"}}>
				{flag &&
					(<a>{this.state.secName}</a>)
				}
				{!flag &&
					(<div style={{"textAlign":"center"}}> <img src="/loading.gif" width="140" alt="" /> </div>)
				}
			</li>
		);
	}
} // SectionItem

export default SectionItem;