import React from "react";
import { Redirect } from "react-router-dom";
import '../App.css';
import { API } from "./API/api";

export class AuthLogoutForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = { redirect: "" };
    }
    componentDidMount() {
        API.logout()
            .then(x => {
				alert("Выход!");
                //this.setState({ redirect: <Redirect to="/auth" /> });
            })
            .catch(x => {
				alert("Выход искл!");
                //this.setState({ redirect: <Redirect to="/auth" /> });
            });
    }
    render() {
        return (
			<div>
				<h2>Подождите ...</h2>
				{this.state.redirect}
			</div>
        );
    }
}

export default AuthLogoutForm;