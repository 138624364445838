import React       from "react";
import {Func}      from "./common/func";
import {API}       from "./API/api";
import HeaderLine  from "./HeaderLine";
import SectionItem from "./SectionItem";
import ObjectList  from "./ObjectList";
import ControlSection from "./ControlSection";

class KB extends React.Component {
	constructor(props) {
		super(props);
		var cfz = Func.get_sess_id();
		//console.log({cfz});

		this.state = {
			sess_id: cfz,
			data: null,
			error: false,
			dbName: '',
			sec_id: 0,
			sec_name: '',
			objList: null,
			editSection: false
		};
		this.selectSection = this.selectSection.bind(this);
	} // constructor

	componentDidMount(){
		this.getNameKB(parseInt(this.props.match.params.base_id)); // получаем имя базы
		this.fetchData();  // получаем разделы базы
	} // componentDidMount
	
	
	getNameKB = (kbID) => {
		return API.point('api_get_name_byid', {"sess_id": this.state.sess_id, "nameEntity": "knowledgebases","ent_id": kbID}, "POST")
			.then(res => {
				this.setState({
					dbName: res
				});
			})
			.catch(err => {
				this.setState({ error: err });
			});
	};
	
	fetchData = () => {
		// Список секций
		return API.point('api_getsections', {"sess_id": this.state.sess_id, "kb_id": this.props.match.params.base_id}, "POST")
			.then(res => {
				this.setState({
					data: res
				});
			})
			.catch(err => {
				this.setState({ error: err });
			});
	};

	selectSection(sec_id, sec_name){
		//console.log({"KB render": this.props.match.params.base_id});
		this.setState({
			sec_id: sec_id,
			sec_name: sec_name
		});
	}

	render () {
		const data = this.state.data;
		//const { sess_id, data, error, dbName, sec_id, objList } = this.state;
		//console.log({data, sess_id, error, dbName, sec_id, objList});

		return (
			<div>
				<HeaderLine
					base_id = {this.props.match.params.base_id}
					sec_id  = {this.state.sec_id}
					obj_id  = {0}
					link_id = {0}
				/>

				<section id="content">
					<div className="content-wrap">
						<div className="container clearfix">
							<h4>{this.state.dbName}</h4>
							<ControlSection
								sess_id   = {this.state.sess_id}
								base_id   = {this.props.match.params.base_id}
								base_name = {this.state.dbName}
								sec_id    = {this.state.sec_id}
								sec_name  = {this.state.sec_name}
							/>

							<div className="tabs side-tabs tabs-bordered clearfix" id="tab-5">
								<ul className="tab-nav clearfix">
									{data && 
										Object.values(data).sort((a, b) => a.id - b.id).map(item => (
											<SectionItem
												id            = {item.id}
												name          = {item.name}
												sess_id       = {this.state.sess_id}
												selectSection = {this.selectSection}
												editSection   = {this.state.editSection}
											/>
										))
									}
									{!data && 
										(<i> ... данные загружаются ... </i>)
									}
								</ul>

								<div className="tab-container">

									<ObjectList
										base_id   = {this.props.match.params.base_id}
										base_name = {this.state.dbName}
										sec_id    = {this.state.sec_id}
										sec_name  = {this.state.sec_name}
										sess_id   = {this.state.sess_id}
										sec_list  = {(!data) ? null : data}
									/>

								</div>
							</div>
						</div>
					</div>
				</section>

			</div>
		);
	}
} // class KB

export default KB;


