import React from 'react';
//import { Func } from "./common/func";
import { API } from "./API/api";
//import HeaderSection from "./HeaderSection";
import ObjectItem from "./ObjectItem";
import $ from "jquery";

class ObjectList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: null,
			isLoaded: false,
			sec_id: 0,
			obj_ID_to_edit: null,
			objectEditArray: null,
			edit: false
		};
		this.addObject = this.addObject.bind(this);
	}
	componentWillReceiveProps(newProps){
		this.fetchData(newProps);
	}
	fetchData = (newProps) => {
		// Список узлов (объектов) раздела
		//console.log({"list objects": newProps.sec_id});
        API.point('api_getobjects', {"sess_id": newProps.sess_id, "sec_id": newProps.sec_id}, "POST")
            .then(res => {
                this.setState({
                    data: res,
                    isLoaded: true,
					sec_id: newProps.sec_id
                });
            })
            .catch(err => {
                //this.setState({ error: err });
            });
	};
	addObject(secID, objID, itemArray, flagEdit){
		this.setState({
			obj_ID_to_edit: objID,
			objectEditArray: itemArray,
			edit: flagEdit
		});
	} // addObject
	render (){
		const data = this.state.data;
		//console.log({"obj fields": data});

		var flag = (parseInt(this.props.sec_id) === 0) ? false : true;

		var nameObj      = '';
		var selectedType = 0;
		var checkedVis   = false;
		var checkedHypo  = false;
		var comment      = '';

		if(this.state.objectEditArray !== null){
			nameObj      = this.state.objectEditArray.name;
			selectedType = this.state.objectEditArray.nodeType;
			checkedVis   = this.state.objectEditArray.visible;
			checkedHypo  = this.state.objectEditArray.hypothesis;
			comment      = this.state.objectEditArray.comment;
		}

		return (
			<>
			{flag &&
				<>
				<div onClick={() => this.addObject(this.state.sec_id, 0, {}, true)} style={{"marginBottom":"25px","color":"#59BA41", "cursor":"pointer"}}>
					<i class="icon-line-square-plus"></i>  <span>Добавить объект в текущий раздел</span>
				</div>
				
				</>
			}

			{data &&
				Object.values(data).sort((a, b) => a.id - b.id).map(item => (
					<ObjectItem
						sess_id   = {this.props.sess_id}
						id        = {item.id}
						name      = {item.name}
						sec_id    = {this.state.sec_id}
						sec_name  = {this.props.sec_name}
						edit      = {this.state.edit}
						obj_id    = {this.state.obj_ID_to_edit}
						addObject = {this.addObject}
						itemArray = {item}
						nameObj      = {nameObj}
						selectedType = {selectedType}
						checkedVis   = {checkedVis}
						checkedHypo  = {checkedHypo}
						comment      = {comment}
						base_name    = {this.props.base_name}
						sec_list     = {this.props.sec_list}
					/>
				))
			}

			{!flag &&
				(<i>... выберите раздел слева для загрузки объектов [{this.state.sec_id}] ...</i>)
			}
			<FormElementObj
				sess_id      = {this.props.sess_id}
				baseID       = {this.props.base_id}
				base_name    = {this.props.base_name}
				secID        = {this.state.sec_id}
				sec_name     = {this.props.sec_name}
				objID        = {this.state.obj_ID_to_edit}
				edit         = {this.state.edit}
				addObject    = {this.addObject}
				sec_list     = {this.props.sec_list}

				objItemArray = {this.state.objectEditArray}
				nameObj      = {nameObj}
				selectedType = {selectedType}
				checkedVis   = {checkedVis}
				checkedHypo  = {checkedHypo}
				comment      = {comment}
			/>
			</>
		);
	}
} // ObjectList

export default ObjectList;

class FormElementObj extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedSectionVal: this.props.secID,
			selectedTypeVal:    this.props.selectedType,
			checkedVisible:     this.props.checkedVis,
			checkedHypo:        this.props.checkedHypo,
			objectName:         this.props.nameObj,
			objectComment:      this.props.comment
		};
		this.closeForm         = this.closeForm.bind(this);
		this.handleVisibleChbx = this.handleVisibleChbx.bind(this);
		this.handleHypoChbx    = this.handleHypoChbx.bind(this);
		this.handleSection     = this.handleSection.bind(this);
		this.handleTypeNode    = this.handleTypeNode.bind(this);
		this.handleObjectName  = this.handleObjectName.bind(this);
		this.handleComment     = this.handleComment.bind(this);
	} // constructor
	componentWillReceiveProps(newProps, newState){
		//console.log({"*** Form Object state": newState, "*** Form Object props": newProps});

		// снова задать состояние перед открытием формы
		var checkedVis   = newProps.objItemArray ? newProps.objItemArray.visible : null ;
		var checkedHyp   = newProps.objItemArray ? newProps.objItemArray.hypothesis : null ;
		var selectedType = newProps.objItemArray ? newProps.objItemArray.nodeType : null;
		var objectName = newProps.objItemArray ? newProps.objItemArray.name : '';
		var objectComment = newProps.objItemArray ? newProps.objItemArray.comment : '';
		this.setState({
			selectedSectionVal: newProps.secID,
			selectedTypeVal:    selectedType,
			checkedVisible: checkedVis,
			checkedHypo:    checkedHyp,
			objectName:    objectName,
			objectComment: objectComment

		});

		if(newProps.edit){			
			this.fill_Labels(newProps.objID);
		}
	} // componentWillReceiveProps
	fill_Labels(objectID){
		if(objectID === null || objectID === undefined){
			return;
		}
		objectID = parseInt(objectID);
		if(objectID === 0){
			$('#newObj-header').html('Создание нового объекта');
			$('#buttonSec').html('Создать объект');
		}else{
			$('#newObj-header').html('Редактировать объект #'+objectID);
			$('#buttonObj').html('Сохранить объект');
		}
		$('#createObj_forma').modal('show');
	} // fillFields
	closeForm(){
		this.props.addObject(this.props.secID, this.props.objID, false);
	}
	handleVisibleChbx(e){
		this.setState({
			checkedVisible: e.target.checked
		});
	}
	handleHypoChbx(e){
		this.setState({
			checkedHypo: e.target.checked
		});
	}
	handleSection = (e) =>{
		this.setState({selectedSectionVal: e.target.value});
	}
	handleTypeNode = (e) =>{
		this.setState({selectedTypeVal: e.target.value});
	}
	handleObjectName = (e) =>{
		this.setState({objectName: e.target.value});
	}
	handleComment = (e) =>{
		this.setState({objectComment: e.target.value});
	}
	_onSubmit(e){
		e.preventDefault();
		//var fd = $('#f_nobj').serialize();
		//alert(fd);
		var ObjID = parseInt($('#ObjID').val());
		if(ObjID === 0 || isNaN(ObjID) || ObjID === null || ObjID === undefined){
			ObjID = 0;
		}
		var dataRequest = {
			"ObjID":     ObjID,
			"secID_Obj": parseInt($('#secID_Obj').val()),
			"nameObj":   $('#nameObj').val(),
			"typeObj":   parseInt($('#typeObj').val()),
			"hypo":      ($('#hypo').val()) ? 1 : 0,
			"vis":       ($('#vis').val())  ? 1 : 0,
			"comment":   $('#comment').val(),
			"sess_id":   $('#sess_id').val()
		}

		console.log({"dataRequest": dataRequest});

		if(ObjID === 0){
			//alert('создаём объект');
			//this.createObj(dataRequest);
			API.point('api_create_obj', dataRequest, "POST");
		}else{
			//alert('редактируем объект');
			//this.changeObj(dataRequest);
			API.point('api_edit_obj', dataRequest, "POST");
		}
		document.location.reload();
	} // _onSubmit
	render () {
		const data = this.props.sec_list
		//console.log({"data list section": data});
		//console.log({"RENDER form elem, props": this.props});
		//console.log({"RENDER form elem, STATE": this.state});

		return (
		<div id="createObj_forma" className="modal fade">
			<div className="modal-dialog">
				<div className="modal-content">
					<div className="modal-header">
						<h4 className="modal-title" id="newObj-header">Создание объекта</h4>
						<div className="text-right" style={{"paddingRight": "7px"}}>
							<button onClick={this.closeForm} type="button" className="close btn closeModalButton" data-dismiss="modal" aria-hidden="true">×</button>
						</div>
					</div>
					<div id="newObj-content" className="modal-body">
						<form id="f_nobj" onSubmit={this._onSubmit}>
							<div class="form-group">
								<b id="nameBase_Obj">{this.props.base_name}</b> :: <i id="nameSec_Obj">{this.props.sec_name} #{this.props.secID}</i>
								<input type="hidden" id="kbID_Obj" value="" />
								<input type="hidden" id="ObjID" value={this.props.objID} />
								<input type="hidden" id="sess_id" value={this.props.sess_id} />
							</div>
							<div className="form-group">
								<label for="url">Раздел: {this.state.selectedSectionVal}</label>
								<div>
									<select
										className = "form-control"
										id        = "secID_Obj"
										style     = {{"width":"100%"}}
										value = {this.state.selectedSectionVal}
										onChange={this.handleSection}>
										{data &&
											Object.values(data).map(item => (
												<option value={item.id}>{item.name}</option>
											))
										}
									</select>
								</div>
							</div>
							<div className="form-group">
								<label for="url">Имя объекта:</label>
								<input
									type         = "text"
									className    = "form-control"
									id           = "nameObj"
									placeholder  = "Имя объекта"
									defaultValue = {this.state.objectName}
									onChange     = {this.handleObjectName}
								/>
							</div>
							<div className="form-group">
								<label for="url">Тип объекта (узла):</label>
								<select
									className    = "form-control"
									id           = "typeObj"
									value = {this.state.selectedTypeVal}
									onChange = {this.handleTypeNode}>
									<option value="0">- выберите тип узла -</option>
									<option value="3">обычный узел</option>
									<option value="1">AND</option>
									<option value="2">OR</option>
								</select>
							</div>
							<div class="form-check form-check-inline">
								<input
									class   = "form-check-input"
									type    = "checkbox"
									id      = "hypo"
									defaultChecked = {this.state.checkedHypo}
									onChange = {this.handleHypoChbx} />
								<label class="form-check-label" for="inlineCheckbox1">гипотеза</label>
							</div>
							<div class="form-check form-check-inline">
								<input
									class   = "form-check-input"
									type    = "checkbox"
									id      = "vis"
									defaultChecked = {this.state.checkedVisible}
									onChange = {this.handleVisibleChbx} />
								<label class="form-check-label" for="inlineCheckbox2">видимый</label>
							</div>
							<div class="form-group">
								<label for="url">Комментарий:</label>
								<textarea
									class = "form-control"
									id    = "comment"
									rows  = "3"
									defaultValue = {this.state.objectComment}
									onChange = {this.handleComment} />
							</div>
							<button type="submit" class="btn btn-primary" id="buttonObj">Создать объект</button>
						</form>
					</div>
				</div>
			</div>
		</div>
		);
	}
} // FormElementObj