import React from 'react';
//import { Func } from "./common/func";
//import { API } from "./API/api";
import LinksList from "./LinksList";

class ObjectItem extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			data: null,
			isLoaded: false,
			objID_for_links_display: 0,
			linksVisible: false
		};
		this.clickLinks = this.clickLinks.bind(this);
		this.addObject = this.addObject.bind(this);
		this.showLinks = this.showLinks.bind(this);
	}
	/*
	componentDidMount(){
		//console.log({"-> did mount obj item":this.props.id});
		this.fetchData();
	}
	componentDidUpdate(prevProps){
		//console.log({"-> did upd obj item":this.props.id, "prev": prevProps.id});

		if(this.props.id !== prevProps.id) {
			this.fetchData();
		}
	}
	fetchData = async () => {
		//console.log({"object item": this.props});
		try {
			const res = await API.point('api_getbyid', { "sess_id": this.props.sess_id, "nameEntity": "objects", "ent_id": this.props.id }, "POST");
			this.setState({
				data: res,
				isLoaded: true
			});
		} catch (err) {
			this.setState({ error: err });
		}
	};
	*/
	clickLinks(){
		//console.log({"state vis": this.state.linksVisible});
		this.setState({linksVisible: true});
	} // 

	addObject(secID, objID, itemArray, flagEdit){
		this.props.addObject(secID, objID, itemArray, flagEdit);
	}
	showLinks(objID_link, flagVisible){
		if(flagVisible){
			this.setState({
				objID_for_links_display: objID_link,
				linksVisible: true
			});
		}else{
			this.setState({
				objID_for_links_display: objID_link,
				linksVisible: false
			});
		}
	}
	render (){
		//const data = this.state.data;
		//console.log({"data obj name": data});
		const item = this.props.itemArray;
		return (
			<div className="container clearfix">
				{/*this.props.itemArray.name*/}
				{/*data && 
					Object.values(data).map(item => (*/}
						<div className="col_full" style={{"marginBottom":"10px"}}>
							<span style={{"fontWeight":"bold"}}>{item.name} </span> #{item.id}
							<div>
								{(item.hypothesis) ? <i className="icon-bell2" title="Гипотеза"></i> : <i className="icon-bell-slash1" title="Не гипотеза"></i>}
									&nbsp;&nbsp;&nbsp;
								{(item.visible)    ? <i className="icon-eye1" title="Видимый узел"></i>  : <i className="icon-eye-slash1" title="Невидимый узел"></i>}
									&nbsp;&nbsp;&nbsp;
								<span title="Тип узла">{item.nodeType}</span>
									&nbsp;&nbsp;&nbsp;
								<i onClick={() => this.showLinks(this.props.id, !this.state.linksVisible)} className="icon-line-link" style={{"cursor":"pointer"}} title="Показать/скрыть связи объекта"></i>
									&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
								<i onClick={() => this.addObject(this.props.sec_id, this.props.id, this.props.itemArray, true)} class="icon-pencil" style={{"color":"#59BA41", "cursor":"pointer"}} title="Редактировать"></i>&nbsp;&nbsp;
								<i class="icon-line-square-cross" style={{"color":"#C02942", "cursor":"pointer"}} title="Удалить"></i>
							</div>
						</div>
					{/*))
				}*/}
				<LinksList
					sess_id      = {this.props.sess_id}
					obj_id        = {item.id}
					objID_link   = {this.state.objID_for_links_display}
					obj_name   = {item.name}
					flagVis      = {this.state.linksVisible}
					clickLinks   = {this.clickLinks}
					showLinks    = {this.showLinks}
					objItemArray = {this.props.itemArray}
					sec_name     = {this.props.sec_name}
					base_name    = {this.props.base_name}
					sec_list     = {this.props.sec_list}
					sec_id     = {this.props.sec_id}
					  
				/>

			</div>
		);
	}
} // ObjectItem

export default ObjectItem;

