import React from 'react';
import '../App.css';
import { Func } from "./common/func";
import { API } from "./API/api";
import HeaderLine from "./HeaderLine";
import $ from "jquery";

class KBasesList extends React.Component {

	constructor(props) {
		super(props);
		var cfz = Func.get_sess_id();
		//console.log({cfz});
		//alert(cfz);

		this.state = {
			sess_id: cfz,
			data: null,
			isLoaded: false,
			error: false,
			kbase_ID_to_edit: null,
			kbase_name_to_edit: null
		};
		this.addKBase = this.addKBase.bind(this);
		this.deleteKBase = this.deleteKBase.bind(this);
	} // constructor
  
	componentDidMount(){
		this.fetchData();
	} // componentDidMount
  
	fetchData = async () => {
		// Список баз
		try {
			const res = await API.point('api_getdbs', { "sess_id": this.state.sess_id }, "POST");
			this.setState({
				data: res,
				isLoaded: true
			});
		} catch (err) {
			this.setState({ error: err });
		}
	}; // fetchData

	addKBase(kbID, kbName) {
		this.setState({kbase_ID_to_edit: kbID, kbase_name_to_edit: kbName});
	} // addKBase

	deleteKBase(kbID) {
		alert("Базы не удаляются! #"+kbID);
	} // deleteKBase

	onSavingName(kbName) {
		this.setState({kbase_name_to_edit: kbName});
	} // addKBase
  
	render(){
		const { sess_id, data, isLoaded, error, kbase_ID_to_edit, kbase_name_to_edit } = this.state;
		//console.log({data, sess_id, error, kbase_ID_to_edit, kbase_name_to_edit});

		return (
			<div>
			<section id="content">
				<div className="content-wrap">
					<div className="container clearfix">
						<div className="postcontent nobottommargin clearfix">
							{isLoaded &&
							<div>
								<div style={{margin: "0px 0px 15px"}}>
									<b>Список баз знаний</b>
								</div>
							</div>
							}
							<div style={{margin: "0px 0px 15px", cursor: "pointer"}} onClick={() => this.addKBase(0, '')}>
								<i className="icon-line-circle-plus"></i><span> Добавить базу</span>
							</div>
							<table className="table table-striped table-hover">
								<tr><th style={{width: "25px"}}>#ID</th><th>Название базы</th><th style={{"textAlign":"center"}}>Действия</th></tr>
							{data && 
								Object.values(data).sort((a, b) => a.id - b.id).map(item => (
									<tr>
										<td>{item.id}</td>
										<td><a href={"/kb/"+(item.id)+"/"}>{item.name}</a></td>
										<td style={{"textAlign":"center", cursor: "pointer"}}>
											<i className="icon-pencil" onClick={() => this.addKBase(item.id, item.name)}></i>
											&nbsp;&nbsp;&nbsp;
											<i className="icon-line-delete" onClick={() => this.deleteKBase(item.id)}></i>
										</td>
									</tr>
								))
							}
							</table>
							{!data && 
								(<i> ... данные загружаются ... </i>)
							}
						</div>
					</div>
				</div>
			</section>
			<FormElement
				kbaseID   = {this.state.kbase_ID_to_edit}
				kbaseName = {this.state.kbase_name_to_edit}
				onSavingName = {() => this.onSavingName(this.state.kbase_name_to_edit)}
			/>
			</div>
		); // return
	} // render
} // KBasesList class

class KBases extends React.Component {
	constructor(props) {
		super(props);
		document.title = "Базы знаний | список";
		Func.testAuth('');
	}

	render () {
		return (
			<div>
			<HeaderLine
				base_id = {0}
				sec_id  = {0}
				obj_id  = {0}
				link_id = {0}
			/>
			<KBasesList />
			</div>
		);
	}
} // class KBases

export default KBases;

class FormElement extends React.Component {

	constructor(props) {
		super(props);
	
		var cfz = Func.get_sess_id();
		//console.log({cfz});
	
		this.state = {
			sess_id: cfz,
			kbaseName: this.props.kbaseName,
			data: null,
			isLoaded: false,
			error: null
		};

		this._onChangeName = this._onChangeName.bind(this);
	
	} // constructor

	_onChangeName(e){
		this.setState({
			kbaseName: e.target.value
		});
		
	} // _onChangeName

	_onSubmit(){
		//alert(this.state.kbaseName+'-'+this.props.kbaseID);
		if(parseInt(this.props.kbaseID) === 0){
			this.createKB();
		}else if(parseInt(this.props.kbaseID) > 0){
			this.changeNameKB();
		}
		this.props.onSavingName(this.state.kbaseName);
		document.location.reload();
	} // _onSubmit

	createKB = async () => {
		try {
			const res = await API.point('api_createkb', { "sess_id": this.state.sess_id, "name": this.state.kbaseName}, "POST");
			this.setState({
				data: res,
				isLoaded: true
			});
		} catch (err) {
			this.setState({ error: err });
		}
	} // createKB

	changeNameKB = async () => {
		try {
			const res = await API.point('api_editkb', { "sess_id": this.state.sess_id, "nameKB": this.state.kbaseName, "kbID_kb": this.props.kbaseID}, "POST");
			this.setState({
				data: res,
				isLoaded: true
			});
		} catch (err) {
			this.setState({ error: err });
		}
	} // changeNameKB

	controlShow(kbaseID){
		if(kbaseID === null || kbaseID === undefined){
			return;
		}
		kbaseID = parseInt(kbaseID);

		if(kbaseID === 0){
			$('#newkb-header').html('Создание новой базы');
			$('#buttonKB').html('Создать базу');
		}else{
			$('#newkb-header').html('Редактировать имя базы #'+kbaseID);
			$('#buttonKB').html('Сохранить имя базы');
		}

		$('#nameKB').val(this.props.kbaseName);
		$('#createNewKB_forma').modal('show');
	} // controlShow

	render () {
		this.controlShow(this.props.kbaseID);
		console.log({"eval":this.state.kbaseName});
		return (
			<div>
				<div id="createNewKB_forma" className="modal fade">
					<div className="modal-dialog">
						<div className="modal-content">
							{/*<!-- Заголовок модального окна -->*/}
							<div className="modal-header">
								<h4 className="modal-title" id="newkb-header">Создание новой базы знаний</h4>
								<div className="text-right" style={{"paddingRight": "7px"}}>
									<button type="button" className="close btn closeModalButton" data-dismiss="modal" aria-hidden="true">×</button>
								</div>
							</div>
							{/*<!-- Основное содержимое модального окна -->*/}
							<div id="newkb-content" className="modal-body">
								
								<input type="hidden" id="kbID_kb" name="kbID_kb" value={this.props.kbaseID} />
								<div className="form-group">
									<label for="url">Имя базы:</label>
									<input type="text" className="form-control" id="nameKB" name="nameKB" onChange={(e)=>this._onChangeName(e)} value={this.state.kbaseName} placeholder="Имя базы знаний" />
								</div>
								<button type="submit" className="btn btn-primary" id="buttonKB" onClick={()=>this._onSubmit()}>Создать базу</button>
								
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
} // FormElement
