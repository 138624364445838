//import AuthForm from "../AuthForm";

export const Func = (function () {
    var fname = {
        setCookie: function (name, value, expires, path, domain, secure){
			//alert("Устанавливаем куки");
            document.cookie = name + "=" + escape(value) +
            ((expires) ? "; expires=" + expires : "") +
            ((path) ? "; path=" + path : "") +
            ((domain) ? "; domain=" + domain : "") +
            ((secure) ? "; secure" : "");
        },
        getCookie: function (name){
            var cookie = " " + document.cookie;
            var search = " " + name + "=";
            var setStr = null;
            var offset = 0;
            var end = 0;
            if (cookie.length > 0) {
                offset = cookie.indexOf(search);
                if (offset != -1) {
                    offset += search.length;
                    end = cookie.indexOf(";", offset)
                    if (end == -1) {
                        end = cookie.length;
                    }
                    setStr = unescape(cookie.substring(offset, end));
                }
            }
			//alert("вот кука: "+setStr);
            return setStr;
        },
        timestampToDate: (ts) => {
            /*
            Преобразует дату из формата timestamp
            в дд.мм.гггг
            */
            var d = new Date();
            d.setTime(ts);
            return ('0' + d.getDate()).slice(-2) + '.' + ('0' + (d.getMonth() + 1)).slice(-2) + '.' + d.getFullYear();
        },
		get_sess_id: () => {
			var cfz = fname.getCookie('sess_id');
			return cfz;
		},
        testAuth: (url) => {
			var cfz = fname.getCookie('sess_id');
			console.log({"testAuth": cfz });
			//alert('test auth cfz -> '+cfz);
			
			if(cfz == '' || cfz == null || cfz == undefined){
				//document.location.href = '/';
				//return false;
			}else{
				if(url != ''){
					document.location.href = url;
				}
				//alert("Авторизовались: "+cfz);
				//return cfz;
			}
        },

    }; // fname
    return fname;
})();